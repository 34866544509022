<template>
  <div>
    <el-card>
      <el-form ref="form"  :inline="true">
        <el-form-item label="app端">
           <el-select
            v-model="queryParams.mold"
            placeholder="请选择app端"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="(item,index) in typeList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>

       <el-form-item label="渠道">
           <el-select
            v-model="queryParams.channel"
            placeholder="请选择渠道"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="(item,index) in channelList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="时间">
          <el-date-picker
            v-model="times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格区域 -->

      <el-table :data="collectionAppList" stripe style="width: 100%" border>
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
        <el-table-column prop="mold" label="app端" align="center">
        </el-table-column>

        <el-table-column prop="version" label="版本号" align="center">
        </el-table-column>
        <el-table-column prop="model" label="机型" align="center">
        </el-table-column>
        <el-table-column prop="brand" label="品牌"  align="center">
        </el-table-column>
        <el-table-column prop="system" label="操作系统" align="center">
        </el-table-column>
        <el-table-column prop="channel" label="渠道" align="center">
        </el-table-column>
        <el-table-column prop="platform" label="平台" align="center">
        </el-table-column>
        <el-table-column prop="user" label="用户信息" align="center">
            <template slot-scope="scope" >
              <div class="user_box" v-if="scope.row.user.avatar">
                <div class="user_img">
                  <img :src="scope.row.user.avatar" alt="" srcset="">
                </div>
                <div class="user_name">
                  {{scope.row.user.nickName}}
                </div>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="时间" width="150" align="center">
          <template slot-scope="scope">
            {{$moment(scope.row.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-slot="scope">
          <el-button type="success" size="small" icon="el-icon-info" circle @click="toDetails(scope.row)"></el-button>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryParams.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>
    </el-card>

     <el-dialog
      :visible.sync="detailsDialog"
      title="详情"
      append-to-body
      width="50%"
      :close-on-click-modal="false"
    > 
      
      <el-form :model="collectionDetailsList" ref="details">
        <el-form-item el-form-item label="Uuid：" label-width="100px">
          {{collectionDetailsList.uuid}}
        </el-form-item>
        <el-form-item el-form-item label="app端：" label-width="100px">
          {{collectionDetailsList.mold}}
        </el-form-item>
        <el-form-item el-form-item label="机型：" label-width="100px">
          {{collectionDetailsList.model}}
        </el-form-item>
        <el-form-item el-form-item label="品牌：" label-width="100px">
          {{collectionDetailsList.brand}}
        </el-form-item>
        <el-form-item el-form-item label="系统：" label-width="100px">
          {{collectionDetailsList.system}}
        </el-form-item>
        <el-form-item el-form-item label="渠道：" label-width="100px">
          {{collectionDetailsList.channel}}
        </el-form-item>
        <el-form-item el-form-item label="平台：" label-width="100px">
          {{collectionDetailsList.platform}}
        </el-form-item>
        <template v-if="!(Array.isArray(collectionDetailsList.user))">
            <el-form-item el-form-item label="用户昵称：" label-width="100px">
                {{collectionDetailsList.user.nickName}}
            </el-form-item>

            <el-form-item el-form-item label="用户头像：" label-width="100px">
                <img class="thumb_img" :src="collectionDetailsList.user.avatar"  alt="">
            </el-form-item>
        </template>

        <template v-if="collectionDetailsList.collect.length!==0">
            <el-form-item el-form-item label="信息收集：" label-width="100px">
                <div class="collection_item" v-for="(item,index) in collectionDetailsList.collect" :key="index">
                  <div class="problem_name">问题:  {{item.key}}</div>
                  <div class="answer">答案:  {{item.value}}</div>
                </div>
            </el-form-item>
        </template>
         
        <el-form-item el-form-item label="用户ID：" label-width="100px">
          {{collectionDetailsList.user_id}}
        </el-form-item>

        <el-form-item el-form-item label="app版本：" label-width="100px">
          {{collectionDetailsList.version}}
        </el-form-item>
        <el-form-item el-form-item label="时间：" label-width="100px">
            {{$moment(collectionDetailsList.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
        </el-form-item>

        <el-form-item el-form-item label="更新时间：" label-width="100px">
            {{$moment(collectionDetailsList.update_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import { CollectionAppList } from "@/api/information";
export default {
  data() {
    return {
      collectionAppList: [],
      queryParams: {
        page: 1,
        limit: 10,
        mold: "",
        channel: "",
        start_time: null,
        end_time: null,
      },
      total: 0,
      times: null,
      detailsDialog: false,
      collectionDetailsList: {
        user: {},
        collect: [],
      },
      typeList: [
        { key: "japanese", value: "日语" },
        { key: "korean", value: "韩语" },
        { key: "french", value: "法语" },
      ],
      channelList: [
        { key: "android", value: "安卓" },
        { key: "ios", value: "苹果" },
        { key: "google", value: "谷歌" },
        { key: "yyb", value: "应用宝" },
        { key: "360", value: "360" },
        { key: "huawei", value: "华为" },
        { key: "xiaomi", value: "小米" },
        { key: "vivo", value: "vivo" },
        { key: "oppo", value: "oppo" },
      ],
    };
  },

  methods: {
    handleSizeChange(size) {
      this.queryParams.limit = size;
      this.getCollectionAppList();
    },
    handleCurrentChange(page) {
      this.queryParams.page = page;
      this.getCollectionAppList();
    },

    // 获取app信息收集列表
    getCollectionAppList() {
      CollectionAppList(this.queryParams).then((res) => {
        this.collectionAppList = res.data.data;
        this.total = res.data.total;
      });
    },

    // 查看详情
    toDetails(item) {
      let data = JSON.parse(JSON.stringify(item));
      this.collectionDetailsList = data;
      this.detailsDialog = true;
      console.log(this.collectionDetailsList);
    },

    // 查询
    onSubmit() {
      this.queryParams.page = 1;
      if (this.times == null) {
        this.queryParams.start_time = null;
        this.queryParams.end_time = null;
      }
      if (this.times) {
        this.queryParams.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryParams.end_time = new Date(this.times[1]).getTime() / 1000;
      }
      this.getCollectionAppList();
    },
  },

  created() {
    this.getCollectionAppList();
  },
};
</script>

<style scoped lang="less">
.el-row {
  margin-bottom: 30px;
  font-size: 24px;
}
.el-tag {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}
.user_box {
  display: flex;
  align-items: center;
  justify-content: center;
  .user_img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .user_name {
    margin-left: 5px;
  }
  .user_role {
    color: red;
  }
}

.thumb_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.collection_item {
  display: flex;
  align-items: center;

  .answer {
    margin-left: 30px;
  }
}
</style>
